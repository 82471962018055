








import Vue from 'vue';
import ComponentCreatePassword from '@/components/CreatePassword.vue';
export default Vue.extend({
  'name': 'Login',
  'components': {
    ComponentCreatePassword
  }
});
